/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <nav aria-label="breadcrumb " class="d-none d-md-block">
      <div class="container">
        <ol class="breadcrumb">
          <li v-for="(name, index) in parentPages" class="breadcrumb-item" :key="index">
            <a href="#">{{ name }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ activePage }}</li>
        </ol>
      </div>
    </nav>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    parentPages: {
      type: Array,
      default: ['Home']
    },
    activePage: {
      type: String,
      default: 'Description',
    },
  },
  name: 'BreadcrumbLink',
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>

<style lang="sass" scoped>
</style>
