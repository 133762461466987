<template>
  <!-- eslint-disable max-len -->
  <div
    class="modal save-design fade"
    :class="{isShowModal: 'show'}"
    id="modalSaveDesign"
    aria-labelledby="modalSaveDesignLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="save-design__title">Save my Design</div>
            <div class="save-design__form active">
            <form class="needs-validation" id="save-design" novalidate>
              <input v-model="productName" class="form-control form-control-lg" type="text" placeholder="Type your design name" aria-label=".form-control-lg example" id="validationCustom03" required>
              <div class="invalid-feedback">
                Please provide a valid product name.
              </div>
              <div class="p-4 d-flex align-items-center">
                <button type="button" class="btn btn-primary me-2" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary-2" @click="saveMyDesign">Save</button>
              </div>
              </form>
            </div>
            <div class="save-design__confirm pb-5">
              <div class="d-flex flex-column align-items-center">
              <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.05" cx="65" cy="65" r="65" fill="url(#paint0_linear_2439_21447)"/>
                <circle opacity="0.2" cx="65" cy="65" r="55" fill="url(#paint1_linear_2439_21447)"/>
                <circle cx="65" cy="65" r="42" fill="url(#paint2_linear_2439_21447)"/>
                <path d="M48 65.711L58.3103 76.2598C58.7058 76.6644 59.3579 76.6607 59.7486 76.2515L81 54" stroke="white" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                <linearGradient id="paint0_linear_2439_21447" x1="-45.2979" y1="34.0725" x2="216.361" y2="39.6991" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF2E57"/>
                <stop offset="1" stop-color="#FF7439"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2439_21447" x1="-28.329" y1="38.8306" x2="193.075" y2="43.5915" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF2E57"/>
                <stop offset="1" stop-color="#FF7439"/>
                </linearGradient>
                <linearGradient id="paint2_linear_2439_21447" x1="-6.26943" y1="45.0161" x2="162.803" y2="48.6517" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF2E57"/>
                <stop offset="1" stop-color="#FF7439"/>
                </linearGradient>
                </defs>
              </svg>
              You have successfully saved.
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
/* eslint-disable */
export default {
  name: 'SaveMyDesignModal',
  props: {
    myDesignData: {
      type: Object
    },
    collections: {
      type: Array
    }
  },
  data() {
    return {
      productName: ''
    };
  },
  mounted() {},
  computed: {},
  methods: {
    saveMyDesign() {
      $this.emit('saveMyDesignEvent', this.productName);
      this.hideModal();
    },
    hideModal() {
      $('#modalSaveDesign').modal('toggle');
    },
    showModal() {
      $('#modalSaveDesign').modal('toggle');
    }
  }
};
</script>

<style lang="sass" scoped>
</style>
