/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <nav class="breadcrumb-show d-none d-md-block">
    <div class="container">
      <a v-if="pageName" href="javascript:(0)" class="breadcrumb-show__item" aria-current="page">{{ pageName }}</a>
      <p class="breadcrumb-show__description">
        {{ pageDescription }}
      </p>
    </div>
  </nav>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    pageName: {
      type: String,
      default: 'page'
    },
    pageDescription: {
      type: String,
      default: 'Description',
    },
  },
  name: 'BreadcrumbTitle',
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>

<style lang="sass" scoped>
</style>
