/* eslint-disable */
const Fonts = {
  fontList: [
    { value: 'Times New Roman', name: 'Times New Roman' },
    {  value: 'aberus', name: 'Aberus' },
    // {  value: 'ablation', name: 'Ablation' },
    {  value: 'adeft', name: 'Adeft' },
    {  value: 'adelya', name: 'Adelya' },
    {  value: 'adios', name: 'Adios' },
    {  value: 'adolfine', name: 'Adolfine' },
    {  value: 'agnostic', name: 'Agnostic' },
    {  value: 'aiden', name: 'Aiden' },
    {  value: 'alder', name: 'Alder' },
    {  value: 'almondia', name: 'Almondia' },
    {  value: 'alomaisland', name: 'Alomaisland' },
    {  value: 'alvida', name: 'Alvida' },
    {  value: 'amaranth', name: 'Amaranth' },
    {  value: 'amateur', name: 'Amateur' },
    {  value: 'amoreiza', name: 'Amoreiza' },
    {  value: 'amorette', name: 'Amorette' },
    {  value: 'angeleno', name: 'Angeleno' },
    {  value: 'angelina', name: 'Angelina' },
    {  value: 'antreas', name: 'Antreas' },
    {  value: 'arial', name: 'Arial' },
    {  value: 'arm', name: 'Arm' },
    {  value: 'artick', name: 'Artick' },
    {  value: 'asap', name: 'Asap' },
    {  value: 'atomosfer', name: 'Atomosfer' },
    {  value: 'atthens', name: 'Atthens' },
    { value: 'avocado', name: 'Avocado' },
    { value: 'baangkarr', name: 'Baangkarr' },
    { value: 'badstar', name: 'Badstar' },
    { value: 'bahram', name: 'Bahram' },
    { value: 'baileyns', name: 'Baileyns' },
    { value: 'barooduck', name: 'Barooduck' },
    { value: 'bearley', name: 'Bearley' },
    { value: 'beat', name: 'Beat' },
    { value: 'bebasneue', name: 'Bebasneue' },
    { value: 'bellquinas', name: 'Bellquinas' },
    { value: 'bengrraas', name: 'Bengrraas' },
    { value: 'beornheard', name: 'Beornheard' },
    { value: 'berretti', name: 'Berretti' },
    { value: 'biker', name: 'Biker' },
    { value: 'blossomberry', name: 'Blossomberry' },
    { value: 'bondie', name: 'Bondie' },
    { value: 'bondrians', name: 'Bondrians' },
    { value: 'boullervard', name: 'Boullervard' },
    { value: 'braga', name: 'Braga' },
    { value: 'brave', name: 'Brave' },
    { value: 'brightorchid', name: 'Brightorchid' },
    { value: 'brioche', name: 'Brioche' },
    { value: 'brocker', name: 'Brocker' },
    { value: 'brocklyn', name: 'Brocklyn' },
    { value: 'brodetto', name: 'Brodetto' },
    { value: 'bronela', name: 'Bronela' },
    { value: 'bronzier', name: 'Bronzier' },
    { value: 'brooklyn', name: 'Brooklyn' },
    { value: 'brownies', name: 'Brownies' },
    { value: 'brushcheetah', name: 'Brushcheetah' },
    { value: 'bubbblegum', name: 'Bubbblegum' },
    { value: 'butternut', name: 'Butternut' },
    { value: 'caang', name: 'Caang' },
    { value: 'cakehtreuks', name: 'Cakehtreuks' },
    { value: 'calamandria', name: 'Calamandria' },
    { value: 'caldwell', name: 'Caldwell' },
    { value: 'camille', name: 'Camille' },
    { value: 'cannoli', name: 'Cannoli' },
    { value: 'carafun', name: 'Carafun' },
    { value: 'carnavonts', name: 'Carnavonts' },
    { value: 'carvino', name: 'Carvino' },
    { value: 'cervantes', name: 'Cervantes' },
    { value: 'chaiaathah', name: 'Chaiaathah' },
    { value: 'chalkyal', name: 'Chalkyal' },
    { value: 'chiqarine', name: 'Chiqarine' },
    { value: 'chukies', name: 'Chukies' },
    { value: 'cincoyo', name: 'Cincoyo' },
    { value: 'clockwise', name: 'Clockwise' },
    { value: 'coast', name: 'Coast' },
    { value: 'college', name: 'College' },
    { value: 'colombo', name: 'Colombo' },
    { value: 'companion', name: 'Companion' },
    { value: 'conseration', name: 'Conseration' },
    { value: 'corlombus', name: 'Corlombus' },
    { value: 'costamala', name: 'Costamala' },
    { value: 'courier', name: 'Courier' },
    { value: 'cozy', name: 'Cozy' },
    { value: 'creamy', name: 'Creamy' },
    { value: 'crossroad', name: 'Crossroad' },
    { value: 'crossroadslab', name: 'Crossroadslab' },
    { value: 'cupello', name: 'Cupello' },
    { value: 'cuprum', name: 'Cuprum' },
    { value: 'daddy', name: 'Daddy' },
    { value: 'dankduck', name: 'Dankduck' },
    { value: 'darbots', name: 'Darbots' },
    { value: 'davish', name: 'Davish' },
    { value: 'davishround', name: 'Davishround' },
    { value: 'dearjournal', name: 'Dearjournal' },
    { value: 'delicious', name: 'Delicious' },
    { value: 'desmodus', name: 'Desmodus' },
    { value: 'desmosedici', name: 'Desmosedici' },
    { value: 'dhangdose', name: 'Dhangdose' },
    { value: 'dingle', name: 'Dingle' },
    { value: 'djangart', name: 'Djangart' },
    { value: 'dolington', name: 'Dolington' },
    { value: 'doppelganger', name: 'Doppelganger' },
    { value: 'dorr', name: 'Dorr' },
    { value: 'dustin', name: 'Dustin' },
    { value: 'earth', name: 'Earth' },
    { value: 'easterica', name: 'Easterica' },
    { value: 'eastone', name: 'Eastone' },
    { value: 'eastwood', name: 'Eastwood' },
    { value: 'echizen', name: 'Echizen' },
    { value: 'elkarnito', name: 'Elkarnito' },
    { value: 'elliot', name: 'Elliot' },
    { value: 'elysian', name: 'Elysian' },
    { value: 'emery', name: 'Emery' },
    { value: 'empire', name: 'Empire' },
    // { value: 'erleane', name: 'Erleane' },
    { value: 'espano', name: 'Espano' },
    { value: 'eugiene', name: 'Eugiene' },
    { value: 'everleigh', name: 'Everleigh' },
    { value: 'exquisite', name: 'Exquisite' },
    { value: 'father', name: 'Father' },
    { value: 'fatherly', name: 'Fatherly' },
    { value: 'fenord', name: 'Fenord' },
    { value: 'finland', name: 'Finland' },
    { value: 'flamingo', name: 'Flamingo' },
    { value: 'forever', name: 'Forever' },
    { value: 'foxy', name: 'Foxy' },
    { value: 'gallerina', name: 'Gallerina' },
    { value: 'game', name: 'Game' },
    { value: 'gamour', name: 'Gamour' },
    { value: 'georgia', name: 'Georgia' },
    { value: 'geronide', name: 'Geronide' },
    { value: 'geronidestamp', name: 'Geronidestamp' },
    { value: 'gingerbreads', name: 'Gingerbreads' },
    { value: 'gingerly', name: 'Gingerly' },
    { value: 'ginglesnowland', name: 'Ginglesnowland' },
    { value: 'gladiolusy', name: 'Gladiolusy' },
    { value: 'glamper', name: 'Glamper' },
    { value: 'goldiedreambox', name: 'Goldiedreambox' },
    { value: 'goorraatt', name: 'Goorraatt' },
    { value: 'gordon', name: 'Gordon' },
    { value: 'gorgeous', name: 'Gorgeous' },
    { value: 'grayson', name: 'Grayson' },
    { value: 'great', name: 'Great' },
    { value: 'grovana', name: 'Grovana' },
    { value: 'gustavo', name: 'Gustavo' },
    { value: 'haahloddoh', name: 'Haahloddoh' },
    { value: 'hadrea', name: 'Hadrea' },
    { value: 'hairmusk', name: 'Hairmusk' },
    { value: 'hakushou', name: 'Hakushou' },
    { value: 'harietta', name: 'Harietta' },
    { value: 'harison', name: 'Harison' },
    { value: 'hattori', name: 'Hattori' },
    { value: 'haystacks', name: 'Haystacks' },
    { value: 'heater', name: 'Heater' },
    { value: 'hedonist', name: 'Hedonist' },
    { value: 'hello', name: 'Hello' },
    { value: 'helloa', name: 'Helloa' },
    { value: 'hellod', name: 'Hellod' },
    { value: 'hellospring', name: 'Hellospring' },
    { value: 'hellowins', name: 'Hellowins' },
    { value: 'heypatricks', name: 'Heypatricks' },
    { value: 'highway', name: 'Highway' },
    { value: 'hippo', name: 'Hippo' },
    { value: 'historia', name: 'Historia' },
    { value: 'hodor', name: 'Hodor' },
    { value: 'hollawind', name: 'Hollawind' },
    { value: 'honey', name: 'Honey' },
    { value: 'honeyspice', name: 'Honeyspice' },
    { value: 'huckleberrie', name: 'Huckleberrie' },
    { value: 'hymnedsans', name: 'Hymnedsans' },
    { value: 'hymnedscript', name: 'Hymnedscript' },
    { value: 'iacottneue', name: 'Iacottneue' },
    { value: 'icebold', name: 'Icebold' },
    { value: 'inder', name: 'Inder' },
    { value: 'indigo', name: 'Indigo' },
    { value: 'irishgold', name: 'Irishgold' },
    { value: 'irishgreen', name: 'Irishgreen' },
    { value: 'itsmine', name: 'Itsmine' },
    { value: 'jacinda', name: 'Jacinda' },
    { value: 'jared', name: 'Jared' },
    { value: 'jarots', name: 'Jarots' },
    { value: 'jomantara', name: 'Jomantara' },
    { value: 'josefin', name: 'Josefin' },
    { value: 'juneville', name: 'Juneville' },
    { value: 'jura', name: 'Jura' },
    { value: 'kaffeesatz', name: 'Kaffeesatz' },
    { value: 'kajika', name: 'Kajika' },
    { value: 'kakamora', name: 'Kakamora' },
    { value: 'kateyblue', name: 'Kateyblue' },
    { value: 'keripik', name: 'Keripik' },
    { value: 'ketapang', name: 'Ketapang' },
    { value: 'kindel', name: 'Kindel' },
    { value: 'kingbirds', name: 'Kingbirds' },
    { value: 'laahleggek', name: 'Laahleggek' },
    { value: 'laborations', name: 'Laborations' },
    { value: 'lafiesta', name: 'Lafiesta' },
    { value: 'landon', name: 'Landon' },
    { value: 'landre', name: 'Landre' },
    { value: 'langley', name: 'Langley' },
    { value: 'laudya', name: 'Laudya' },
    { value: 'leaderson', name: 'Leaderson' },
    { value: 'leath', name: 'Leath' },
    { value: 'legendum', name: 'Legendum' },
    { value: 'leobronx', name: 'Leobronx' },
    { value: 'little', name: 'Little' },
    { value: 'littlegirl', name: 'Littlegirl' },
    { value: 'lobster', name: 'Lobster' },
    { value: 'lotuseater', name: 'Lotuseater' },
    { value: 'lovalicious', name: 'Lovalicious' },
    { value: 'loverstruck', name: 'Loverstruck' },
    { value: 'maallaangbronks', name: 'Maallaangbronks' },
    { value: 'madison', name: 'Madison' },
    { value: 'magic', name: 'Magic' },
    { value: 'magicwinter', name: 'Magicwinter' },
    { value: 'maileho', name: 'Maileho' },
    { value: 'maldisa', name: 'Maldisa' },
    { value: 'malinsha', name: 'Malinsha' },
    { value: 'mapleleaves', name: 'Mapleleaves' },
    { value: 'market', name: 'Market' },
    { value: 'marones', name: 'Marones' },
    { value: 'mastiff', name: 'Mastiff' },
    { value: 'maxton', name: 'Maxton' },
    { value: 'meandmydog', name: 'Meandmydog' },
    { value: 'meidy', name: 'Meidy' },
    { value: 'menighampiil', name: 'Menighampiil' },
    { value: 'merrymerry', name: 'Merrymerry' },
    { value: 'mewton', name: 'Mewton' },
    { value: 'molot', name: 'Molot' },
    { value: 'momday', name: 'Momday' },
    { value: 'moms', name: 'Moms' },
    { value: 'monday', name: 'Monday' },
    { value: 'monetta', name: 'Monetta' },
    { value: 'mongli', name: 'Mongli' },
    { value: 'montour', name: 'Montour' },
    { value: 'mooka', name: 'Mooka' },
    { value: 'moremagic', name: 'Moremagic' },
    { value: 'morgana', name: 'Morgana' },
    { value: 'morissette', name: 'Morissette' },
    { value: 'mountain', name: 'Mountain' },
    { value: 'ms', name: 'Ms' },
    { value: 'najova', name: 'Najova' },
    { value: 'natarajasana', name: 'Natarajasana' },
    { value: 'natureforce', name: 'Natureforce' },
    { value: 'naville', name: 'Naville' },
    { value: 'neorah', name: 'Neorah' },
    { value: 'new', name: 'New' },
    { value: 'nightjump', name: 'Nightjump' },
    { value: 'nofire', name: 'Nofire' },
    { value: 'northavellion', name: 'Northavellion' },
    { value: 'northern', name: 'Northern' },
    { value: 'northhigh', name: 'Northhigh' },
    { value: 'northhighsdw', name: 'Northhighsdw' },
    { value: 'nosiesta', name: 'Nosiesta' },
    { value: 'notes', name: 'Notes' },
    { value: 'novante', name: 'Novante' },
    { value: 'nunu', name: 'Nunu' },
    { value: 'offlander', name: 'Offlander' },
    { value: 'oliemolly', name: 'Oliemolly' },
    { value: 'oracles', name: 'Oracles' },
    { value: 'ostern', name: 'Ostern' },
    { value: 'otakaku', name: 'Otakaku' },
    { value: 'out', name: 'Out' },
    { value: 'overdose', name: 'Overdose' },
    { value: 'paabaalioot', name: 'Paabaalioot' },
    { value: 'paintedgallery', name: 'Paintedgallery' },
    { value: 'pandoura', name: 'Pandoura' },
    { value: 'papa', name: 'Papa' },
    { value: 'paradise', name: 'Paradise' },
    { value: 'patlabour', name: 'Patlabour' },
    { value: 'patriot', name: 'Patriot' },
    { value: 'peachmarket', name: 'Peachmarket' },
    { value: 'peanut', name: 'Peanut' },
    { value: 'peonyrose', name: 'Peonyrose' },
    { value: 'perfectthoughts', name: 'Perfectthoughts' },
    { value: 'pinacolada', name: 'Pinacolada' },
    { value: 'pinta', name: 'Pinta' },
    { value: 'piqolo', name: 'Piqolo' },
    { value: 'pixelo', name: 'Pixelo' },
    { value: 'plunge', name: 'Plunge' },
    { value: 'ponch', name: 'Ponch' },
    { value: 'pop', name: 'Pop' },
    { value: 'popera', name: 'Popera' },
    { value: 'powder', name: 'Powder' },
    { value: 'practish', name: 'Practish' },
    { value: 'print', name: 'Print' },
    { value: 'pulsate', name: 'Pulsate' },
    { value: 'qlassik', name: 'Qlassik' },
    { value: 'quatro', name: 'Quatro' },
    { value: 'quattrocento', name: 'Quattrocento' },
    { value: 'queen', name: 'Queen' },
    { value: 'queenata', name: 'Queenata' },
    { value: 'quella', name: 'Quella' },
    { value: 'questro', name: 'Questro' },
    { value: 'quickie', name: 'Quickie' },
    { value: 'quillink', name: 'Quillink' },
    { value: 'quish', name: 'Quish' },
    { value: 'ranania', name: 'Ranania' },
    { value: 'rankingscaps', name: 'Rankingscaps' },
    { value: 'raquella', name: 'Raquella' },
    { value: 'raroedette', name: 'Raroedette' },
    { value: 'rawwwing', name: 'Rawwwing' },
    { value: 'reepouh', name: 'Reepouh' },
    { value: 'reewoooh', name: 'Reewoooh' },
    { value: 'regan', name: 'Regan' },
    { value: 'reilly', name: 'Reilly' },
    { value: 'remembrance', name: 'Remembrance' },
    { value: 'republiko', name: 'Republiko' },
    { value: 'retrocycles', name: 'Retrocycles' },
    { value: 'retros', name: 'Retros' },
    { value: 'revans', name: 'Revans' },
    { value: 'reynoldssquare', name: 'Reynoldssquare' },
    { value: 'rhapsody', name: 'Rhapsody' },
    { value: 'riverside', name: 'Riverside' },
    { value: 'roars', name: 'Roars' },
    { value: 'rockford', name: 'Rockford' },
    { value: 'rockrace', name: 'Rockrace' },
    { value: 'rocky', name: 'Rocky' },
    { value: 'rogue', name: 'Rogue' },
    { value: 'rolves', name: 'Rolves' },
    { value: 'romaniesta', name: 'Romaniesta' },
    { value: 'romelio', name: 'Romelio' },
    { value: 'roossooh', name: 'Roossooh' },
    { value: 'ropa', name: 'Ropa' },
    { value: 'roque', name: 'Roque' },
    { value: 'rosario', name: 'Rosario' },
    { value: 'roundaries', name: 'Roundaries' },
    { value: 'rowan', name: 'Rowan' },
    { value: 'roxv', name: 'Roxv' },
    { value: 'salmonberry', name: 'Salmonberry' },
    { value: 'san', name: 'San' },
    { value: 'sants', name: 'Sants' },
    { value: 'sanur', name: 'Sanur' },
    { value: 'saqanone', name: 'Saqanone' },
    { value: 'scriptonesia', name: 'Scriptonesia' },
    { value: 'search', name: 'Search' },
    { value: 'searchlight', name: 'Searchlight' },
    { value: 'secretary', name: 'Secretary' },
    { value: 'sedalia', name: 'Sedalia' },
    { value: 'see', name: 'See' },
    { value: 'serenity', name: 'Serenity' },
    { value: 'servant', name: 'Servant' },
    { value: 'sheraton', name: 'Sheraton' },
    { value: 'shoelaces', name: 'Shoelaces' },
    { value: 'silvertone', name: 'Silvertone' },
    { value: 'smoother', name: 'Smoother' },
    { value: 'spotlightschunky', name: 'Spotlightschunky'},
    { value: 'spotlightsthin', name: 'Spotlightsthin' },
    { value: 'springbreak', name: 'Springbreak' },
    { value: 'squiborn', name: 'Squiborn' },
    { value: 'staatliches', name: 'Staatliches' },
    { value: 'stardust', name: 'Stardust' },
    { value: 'stoothgart', name: 'Stoothgart' },
    { value: 'summer', name: 'Summer' },
    { value: 'summerjoy', name: 'Summerjoy' },
    { value: 'summernude', name: 'Summernude' },
    { value: 'summerpeach', name: 'Summerpeach' },
    { value: 'summersolstice', name: 'Summersolstice' },
    { value: 'summery', name: 'Summery' },
    { value: 'sun', name: 'Sun' },
    { value: 'sunagain', name: 'Sunagain' },
    { value: 'sunnybay', name: 'Sunnybay' },
    { value: 'sunshine', name: 'Sunshine' },
    { value: 'sunstreets', name: 'Sunstreets' },
    { value: 'super', name: 'Super' },
    { value: 'surface', name: 'Surface' },
    { value: 'sweeneey', name: 'Sweeneey' },
    { value: 'tabu', name: 'Tabu' },
    { value: 'teaspoon', name: 'Teaspoon' },
    { value: 'teodore', name: 'Teodore' },
    { value: 'texgyreheros', name: 'Texgyreheros' },
    { value: 'thecalifornia', name: 'Thecalifornia' },
    { value: 'theloveformoney', name: 'Theloveformoney' },
    { value: 'thestegris', name: 'Thestegris' },
    { value: 'thirtylane', name: 'Thirtylane' },
    { value: 'titian', name: 'Titian' },
    { value: 'todes', name: 'Todes' },
    { value: 'torberta', name: 'Torberta' },
    { value: 'trebuchet', name: 'Trebuchet' },
    { value: 'tropicalcoast', name: 'Tropicalcoast' },
    { value: 'twigs', name: 'Twigs' },
    { value: 'twirly', name: 'Twirly' },
    { value: 'unreal', name: 'Unreal' },
    { value: 'vaganza', name: 'Vaganza' },
    { value: 'valeria', name: 'Valeria' },
    { value: 'valery', name: 'Valery' },
    { value: 'verdana', name: 'Verdana' },
    { value: 'vignette', name: 'Vignette' },
    { value: 'von', name: 'Von' },
    { value: 'vonn', name: 'Vonn' },
    { value: 'voyager', name: 'Voyager' },
    { value: 'wandering', name: 'Wandering' },
    { value: 'wargate', name: 'Wargate' },
    { value: 'westernshooter', name: 'Westernshooter' },
    { value: 'westjava', name: 'Westjava' },
    { value: 'whitewonder', name: 'Whitewonder' },
    { value: 'wintersunrise', name: 'Wintersunrise' },
    { value: 'wonderulwilderness', name: 'Wonderulwilderness' },
    { value: 'yoseline', name: 'Yoseline' },
    { value: 'zombie', name: 'Zombie' },
    { value: 'learningtreeelementary', name: 'Learning Tree Elementary' },
    { value: 'lemongrass', name: 'Lemon Grass' },
    { value: 'lumpiabrushfree', name: 'Lumpia Brush Free' },
    { value: 'mightymantis', name: 'Mighty Mantis Personal Use Without Watermark' },
    { value: 'nexarustslab', name: 'NexaRustSlab-BlackShadow01' },
    { value: 'september', name: 'SEPTEMBER 7, 2021' },
    { value: 'spicypumpkin', name: 'Spicy Pumpkin' },
    { value: 'twirrewyn', name: 'Twirrewyn DEMO' },
    { value: 'princess', name: "EMMA’S-8-princess" },
    { value: 'alsmostjpcartoon', name: "Almost Japanese Cartoon" },
    { value: 'alsmostjpcomic', name: "Almost Japanese Comic" },
    { value: 'animeinept', name: "Anime Inept" },
    { value: 'happycartoondemoregular', name: "HappyCartoonDemoRegular" },
    { value: 'japanese3017', name: "Japanese 3017" },
    { value: 'kingcartoondemoregular', name: "KingCartoonDemoRegular" },
    { value: 'animeace2_bld', name: "Animeace 2 Bld" },
    { value: 'animeace2_ital', name: "Animeace 2 Ital" },
    { value: 'animeace2_reg', name: "Animeace 2 Reg" }
  ],
};

export default Fonts;
