<template>
  <!-- eslint-disable max-len -->
  <div
    class="modal fade preview-modal"
    id="modalPreviewNewDesign"
    tabindex="-1"
    aria-labelledby="modalPreviewNewDesignLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fix-bottom modal-dialog-centered ">
      <div class="modal-content">
        <div class="d-flex justify-content-between">
          <div class="p-4 pb-2 d-none d-md-block">
            <h3 class="modal-title">{{ previewName }} - Ice Cream Summer</h3>
            <p class="modal-author" v-if="currentUser.name"><span>by</span> {{ currentUser.name }}</p>
          </div>
          <button
            type="button"
            class="btn pt-3 d-flex"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closePreviewDesign"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="3"
                width="25"
                height="1.5"
                rx="0.75"
                transform="rotate(45 4 3)"
                fill="#656782"
              />
              <rect
                x="2.93945"
                y="20.6777"
                width="25"
                height="1.5"
                rx="0.75"
                transform="rotate(-45 2.93945 20.6777)"
                fill="#656782"
              />
            </svg>
          </button>
        </div>
        <div class="modal-body p-4">
          <div class="modal-main-content">
            <div class="row preview-layout1" v-show="previewImages.length === 0">
              <div class="col-md-2">
                <PuSkeleton height="50px" v-for="index in itemsLoading" :key="index">
                </PuSkeleton>
              </div>
              <div class="col-md-10">
                <PuSkeleton height="300px">
                </PuSkeleton>
              </div>
            </div>
            <div class="row preview-layout" v-show="previewImages.length !== 0">
              <div class="col-md-2">
                <div class="thumb-slider">
                  <div
                    class="mb-2"
                    v-for="(data, index) in previewImages"
                    :key="index"
                    @click="changePreview(index)"
                  >
                  <PuSkeleton height="50px" :loading="!data">
                    <img :src="data" alt="" class="w-100"/>
                  </PuSkeleton>
                  </div>
                </div>
              </div>
              <div class="col-md-10">
                <div class="preview-slider">
                  <div
                    class="blendmode"
                    v-for="(data, index) in previewImages"
                    :key="index"
                    v-show="index == indexPreview"
                  >
                    <div class="blendmode-item">
                      <PuSkeleton height="300px" :loading="!data">
                        <img :src="data" alt=""/>
                      </PuSkeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- eslint-enable max-len -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PreviewNewDesignModal',
  props: {
    isShowModal: {
      type: Boolean,
      require: true,
      default: false,
    },
    previewImages: {
      type: Array,
      require: true,
      default: null,
    },
    viewFront: {
      type: Boolean,
      require: true,
      default: false,
    },
    shirtType: {
      type: String,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      indexPreview: 0,
      statusChanged: false,
      itemsLoading: [0, 1, 2, 3],
    };
  },
  updated() {
    if (!this.viewFront && !this.statusChanged) {
      this.indexPreview = 0;
    }
  },
  computed: {
    previewName() {
      return this.shirtType.charAt(0).toUpperCase() + this.shirtType.slice(1);
    },
    ...mapGetters({
      currentUser: 'authentication/currentUser',
    }),
  },
  watch: {
    previewImages() {
      this.indexPreview = 0;
    },
  },
  methods: {
    changePreview(index) {
      if (!this.viewFront) {
        this.statusChanged = true;
      }
      this.indexPreview = index;
    },
    /* eslint-disable */
    hideModal() {
      $('#modalPreviewNewDesign').modal('toggle');
    },
    closePreviewDesign() {
      $('.design-preview').removeClass('active');
      $('.design-view').addClass('active');
      $('.design-view').removeClass('unactive');
    }
  }
};
</script>

<style lang='sass' scoped></style>
