import { render, staticRenderFns } from "./BreadcrumbTitle.vue?vue&type=template&id=52747c7e&scoped=true&"
import script from "./BreadcrumbTitle.vue?vue&type=script&lang=js&"
export * from "./BreadcrumbTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52747c7e",
  null
  
)

export default component.exports