<template>
  <div
    class="modal modal-share fade"
    :class="{ isShowModal: 'show' }"
    id="modalCheckoutPreview"
    tabindex="-1"
    aria-labelledby="modalCheckoutPreview"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-checkout-preview">
      <div class="modal-content">
        <div class="d-flex align-items-center justify-content-between modal-head">
          <h3 class="modal-checkout-preview__title mb-0">Mini cart</h3>
          <button
            class="btn btn-close p-0"
            id="close-tool-edit"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body order-list">
          <div class="items">
            <div class="order-list__item">
              <img :src="designThumbnail" alt="" />
              <div class="order-list__item--content">
                <h3 class="title">{{productName}}</h3>
                <p>{{productDescription}}</p>
                <p>Color: {{ capitalizeFirstLetter(bgColorNames['k' + background]) }}</p>
                <p>Size: {{ cartSize }}</p>
              </div>
            </div>
          </div>
          <div class="price-item mt-3 mb-5">
            <div class="price mb-3 d-flex align-items-center justify-content-between">
              <h5 class="price-item__title">Shirt fee:</h5>
              <div class="price-item__value">$ {{shirtFee}}</div>
            </div>
          </div>
          <div class="d-flex flex-column">
            <!-- <a class="btn btn-primary w-100 mb-2" href="/cart">View Cart</a> -->
            <a class="btn btn-primary-2 w-100 mb-2" href="/cart">Proceed To Checkout</a>
            <a class="btn btn-primary w-100 mb-2" href="/collection">Continue Shopping</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BG_COLOR_NAMES } from "../../constants/colors";
export default {
  name: "MiniCart",
  props: ["isShowModal", "productName", "shirtFee", "background", 'productDescription', 'cartSize', 'designThumbnail'],
  data() {
    return {
      bgColorNames: BG_COLOR_NAMES
    };
  },
  mounted() {},
  computed: {},
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    hideModal() {
      $("#modalCheckoutPreview").modal("toggle");
    }
  }
};
</script>

<style lang="sass" scoped></style>
