/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <div class="order-print d-none d-md-block">
    <!-- <div class="order-print__head d-none d-lg-block">
                  <h3 class="title">Order Print</h3>
                </div> -->
    <div
      class="w-100 order-print__head colappse-effect d-flex align-items-center justify-content-between"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseExample"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      <h3 class="title">Order Print</h3>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_984_7138)">
          <path
            d="M12.6569 4.34315L7.70711 9.29289C7.31658 9.68342 6.68342 9.68342 6.29289 9.29289L1.34315 4.34315"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_984_7138">
            <rect width="14" height="14" fill="white" transform="translate(14) rotate(90)" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="order-print__content collapse" id="collapseExample">
      <div class="row">
        <div class="col-md-6 col-xl-12">
          <div class="size">
            <h5 class="order-print__title mb-2">Size</h5>
            <div class="size__option">
              <button class="btn size__option--item">XS</button>
              <button class="btn size__option--item">S</button>
              <button class="btn size__option--item">M</button>
              <button class="btn size__option--item active">L</button>
              <button class="btn size__option--item">XL</button>
              <button class="btn size__option--item">XXL</button>
            </div>
          </div>
          <div class="quality mb-lg-3 d-flex">
            <h5 class="order-print__title">Quantity</h5>
            <div class="quality__control">
              <button class="btn p-0">
                <img src="../../assets/images/minus-control.png" alt="" />
              </button>
              <input type="text" value="1" />
              <button class="btn p-0">
                <img src="../../assets/images/plus-control.png" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-12">
          <div class="price-item">
            <div class="price mb-3 d-flex align-items-center justify-content-between">
              <h5 class="order-print__title">Shirt fee:</h5>
              <div class="price__value">$ 100</div>
            </div>
            <div class="price mb-3 d-flex align-items-center justify-content-between">
              <h5 class="order-print__title">Print fee:</h5>
              <div class="price__value">$ 50</div>
            </div>
          </div>
          <hr class="price-line-total" />
          <div class="price mb-3 d-flex align-items-center justify-content-between">
            <h5 class="order-print__title">Total</h5>
            <div class="price__value">$ 150</div>
          </div>
          <button class="btn btn-primary w-100 mt-auto mb-3">Add to cart</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'OrderPrintPopup',
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {}
};
</script>

<style lang="sass" scoped>
</style>
